<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    newsHotList,
    deleteNewsHot,
    saveNewsHot
  } from "@/api/web/news.js";

  /**
   * 热搜管理列表
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "新闻公告列表 ",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        query: {
          keyword: "",
          bigTypeId: "",
          lbbm: "",
          begDate: "",
          endDate: "",
          pageNum: 1,
          pageSize: 20,
          year: ""
        },
        checked: true,
        list: [],
        bigTypeList: [],
        smallTypeList: [],
        newsList: [],
        classList: [],
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0,
        years: [],

        // 弹窗显示
        showmodal: false,
        // 弹窗标题
        dialogTitle: "",
        // 表单
        addForm:{
          hotTitle: "",
          sid: "",
          pxh: "",
          url: ""
        }
      };
    },
    mounted() {
      this.getList();
    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询热搜列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        newsHotList(this.query).then(res => {
          if (res.status) {
            this.list = res.data
            this.total = res.total
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页
        this.getList() //渲染数据的函数
      },
      /** 删除新闻 */
      deleteNewsHot(newsId, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteNewsHot(newsId).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
            this.getList()
          })
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
      /** 弹窗编辑 */
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加热搜"
        this.addForm = {
          hotTitle: "",
          sid: "",
          pxh: "",
          url: ""
        }
      },
      /** 弹窗编辑 */
      editItem(obj) {
        this.addForm = obj
        this.showmodal = true
        this.dialogTitle = "修改热搜"
      },

      /** 校验新闻参数 */
      checkForm() {
        if (!this.addForm.hotTitle) {
          this.checkAlert("热搜名称不能为空")
          return false;
        }
        if (!this.addForm.url) {
          this.checkAlert("热搜链接不能为空")
          return false;
        }
        if (!this.addForm.pxh) {
          this.checkAlert("排序号不能为空")
          return false;
        }
        return true;
      },
      /** 校验提醒 */
      checkAlert(message) {
        this.$message({
          type: 'error',
          message: message
        });
      },
      /** 提交保存 */
      submitForm() {
        // 校验数据
        if (this.checkForm()) {
          // 提交
          let params = this.addForm
          saveNewsHot(params).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '添加成功!'
              });
              window.location.reload()
            }
          })
        }
      }
    }
  };
</script>

<style lang="scss">
 .el-form-item {
   margin-bottom: 0px;
 }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 check-table-top">
              <form class="flexList" style="flex: 1;">
                <input placeholder="输入关键字" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <b-button variant="info" class="btn btn-info h30 flexList mr-2" @click="searchClick()"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</b-button>
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加热搜</b-button>
              </form>

              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%"> 序号</th>
                    <th style="width:10%">热搜标题</th>
                    <th>热搜链接</th>
                    <th style="width:5%">排序号</th>
                    <th style="width:5%">发布人</th>
                    <th style="width:7%">发布时间</th>
                    <th style="width:3%">编辑</th>
                    <th style="width:3%">删除</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in list" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.hotTitle}} </td>
                    <td>
                      <a class="font-blue" target="_blank" :href="obj.url">
                        <div>{{ obj.url }}</div>
                      </a>
                    </td>
                    <td>{{obj.pxh}}</td>
                    <td>{{obj.createUser}}</td>
                    <td>{{obj.createTime != null ? obj.createTime.substring(0,10) : ""}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two" title="修改"
                        @click="editItem(obj)"></i></td>
                    <td class="tab-icon" @click="deleteNewsHot(obj.sid,obj.hotTitle)"><i
                        class="iconfont icon-riLine-delete-bin-line" title="删除"></i></td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="perPage" layout="total, sizes,jumper, prev, pager, next"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>

    <!-- 弹窗开始 -->
    <b-modal id="addClass" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>热搜名称：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" value="" maxlength="20"  placeholder="高辨识度且简短"
                       v-model="addForm.hotTitle" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>热搜链接：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="url" value="" maxlength="255"  placeholder="链接地址"
                       v-model="addForm.url" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>排序数值：</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-input id="number" value="1" type="number" name="number" v-model="addForm.pxh" class="w-100 h30"
                              placeholder="序号越小排序越靠前">
                </b-form-input>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
                                              @click="submitForm">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>


    </b-modal>
    <!-- 弹窗结束-->
  </Layout>
</template>
