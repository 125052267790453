import { getRequest, postRequest, downloadRequest, patchRequest, deleteRequest } from "@/libs/axios";

// ------------------------新闻公告管理-----------------------
// 新闻列表
export function newsList(params) {
    return getRequest("/yethan/web/news/listPage", params);
}
// 新闻列表-导出
export function newsListExport(params) {
    return downloadRequest("/yethan/web/news/listPage/exportExcel", params);
}
// 新闻修改状态
export function changeStatus(params) {
    return patchRequest("/yethan/web/news/changeStatus", params);
}
// 删除新闻
export function deleteNews(sid) {
    return deleteRequest("/yethan/web/news/"+sid);
}
// 新闻大类列表
export function bigTypeList(params) {
    return getRequest("/yethan/web/news/bigTypeList", params);
}
// 新闻小类列表
export function smallTypeList(params) {
    return getRequest("/yethan/web/news/smallTypeList", params);
}
// 新闻详情
export function newsDetail(newsId) {
    return getRequest("/yethan/web/news/"+newsId);
}
// 新闻保存
export function saveNews(params) {
    return postRequest("/yethan/web/news", params);
}
// 更新浏览量
export function newsViewCount(newsId) {
    return  getRequest("/yethan/web/news/viewCount/"+newsId, )
}
// 新闻列表-不分页
export function newsListNoPage(params) {
    return getRequest("/yethan/web/news/list", params);
}
// 热搜列表
export function newsHotList(params) {
    return getRequest("/yethan/web/newsHot/listPage", params);
}
// 新闻保存
export function saveNewsHot(params) {
    return postRequest("/yethan/web/newsHot", params);
}
// 删除新闻
export function deleteNewsHot(sid) {
    return deleteRequest("/yethan/web/newsHot/"+sid);
}
